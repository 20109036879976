// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Scroll Btn */
.scroll-up {
    width: 45px;
    height: 45px;
    display: none;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: white;
    border: 3px solid white;
    border-radius: 50%;
    background-color: #9D2553;
    position: fixed;
    bottom: 50px;
    right: 50px;
    font-size: 24px;
    z-index: 20;
    cursor: pointer;
}  
.show-scroll {
  display: flex;
}
/* Responsive */

@media screen and (max-width: 600px) {
    .scroll-up {
        bottom: 32px;
        right: 32px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Styles/ScrollUp.css"],"names":[],"mappings":";AACA,eAAe;AACf;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,YAAY;IACZ,WAAW;IACX,eAAe;IACf,WAAW;IACX,eAAe;AACnB;AACA;EACE,aAAa;AACf;AACA,eAAe;;AAEf;IACI;QACI,YAAY;QACZ,WAAW;IACf;AACJ","sourcesContent":["\n/* Scroll Btn */\n.scroll-up {\n    width: 45px;\n    height: 45px;\n    display: none;\n    align-items: center;\n    text-align: center;\n    justify-content: center;\n    color: white;\n    border: 3px solid white;\n    border-radius: 50%;\n    background-color: #9D2553;\n    position: fixed;\n    bottom: 50px;\n    right: 50px;\n    font-size: 24px;\n    z-index: 20;\n    cursor: pointer;\n}  \n.show-scroll {\n  display: flex;\n}\n/* Responsive */\n\n@media screen and (max-width: 600px) {\n    .scroll-up {\n        bottom: 32px;\n        right: 32px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
